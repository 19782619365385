.emptystate {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-client {
        min-height: 40dvh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &-text {
      color: var(--grey-text);
      font-size: 20px;
    }
  }
}
