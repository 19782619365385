.analytics {
  &__container {
    height: 100%;

    &-header {
      margin-bottom: 20px !important;
    }

    &-chart {
      height: 300px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e5e7eb;
      margin-bottom: 24px;
      padding: 24px;

      &-title {
        font-size: 18px;
        font-weight: 500;
      }

      .recharts-responsive-container {
        display: flex !important;
        align-items: center !important;
      }

      .recharts-wrapper {
        width: 900px !important;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 500;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }

    &-right {
      max-height: calc(100dvh - 64px);
      overflow: scroll;
      background-color: #fff;
      padding: 20px;
      height: 100dvh;

      table {
        border-radius: 8;
        border: 1px solid #e5e7eb;

        th {
          text-transform: uppercase;
          color: var(--grey-text) !important;
          font-size: 12px !important;
          line-height: 16px !important;
          font-weight: 500 !important;
        }
      }

      @media screen and (max-width: 768px) {
        min-height: calc(100dvh - 64px - 57px);
        max-height: calc(100dvh - 64px - 57px);
      }
    }
  }
}
