@import url("./ant-override.scss");
@import url("./variable.scss");
.badge-soap {
  & sup {
    background: #eef2ff !important;
    color: #312e81 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    height: 24px !important;
  }
}
.badge-soap-speech {
  & sup {
    background: #faf5ff !important;
    color: #5b21b6 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    height: 24px !important;
  }
}
.badge-intake {
  & sup {
    background: #f0fdfa !important;
    color: #134e4a !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    height: 24px !important;
  }
}

.badge-emdr {
  & sup {
    background: #fdf2f8 !important;
    color: #831843 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    height: 24px !important;
  }
}
.badge-dap {
  & sup {
    background: #fffbeb !important;
    color: #78350f !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    height: 24px !important;
  }
}

.secondary-button {
  background-color: #eef2ff;
  color: var(--indigo-700);
  font-weight: 500;
}
#special-card {
  border: 1px solid #c7d2ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
#special-card .ant-card-head,
#special-card .ant-collapse-header {
  background-color: #e0e7ff;
  color: #4f46e5;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#special-card .ant-collapse-header-text {
  color: #4f46e5;
  font-size: 16px;
  font-weight: 600;
}

#notes-container .ant-collapse {
  border: 0px;
  margin-bottom: 20px;
}

#special-card .ant-collapse-content-box {
  background: var(--blue-bg);
}

.mobile-action-button {
  padding: 0px 5px !important;
  align-items: center;
}
.ant-btn-default.mobile-action-button {
  color: var(--secondary-text) !important;
}
.mobile-action-button.primary span {
  color: var(--primary) !important;
}
.empty-state-note {
  display: block;
}
.empty-state-note-mobile {
  display: block;
  margin: 20px auto;
  width: 100px;
}
.empty-action-button {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}
@media screen and (max-width: 768px) {
  .mobile-action-button {
    padding: 0px 5px !important;
  }
  .empty-state-note {
    display: none;
  }
  .empty-state-note-mobile {
    display: block;
    margin: 20px auto;
    width: 100px;
  }
  .empty-action-button {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}
.tos ol {
  counter-reset: item;
}
.tos li {
  display: block;
}
.tos li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: 600;
}