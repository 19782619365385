.progresssummary {
  &-header {
    font-weight: bold;
    font-size: 1rem;
    margin: 0.5rem 0;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
