.layout {
  &__container {
    overflow: hidden;
    width: 100dwh;
    height: 100dvh;
    background-color: #e5e7eb;
  }
  &__header {
    color: #fff;
    height: 64px;
    padding-inline: 48px;
    line-height: 64px;
    width: 100%;
    background-color: transparent;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7eb;

    @media screen and (max-width: 768px) {
      padding-inline: 20px;
    }

    @media screen and (max-width: 450px) {
      padding-inline: 10px;
    }

    @media screen and (max-width: 367px) {
      padding-inline: 10px;
    }

    & > svg {
      @media screen and (max-width: 367px) {
        width: 50px;
      }
    }

    &-text {
      color: #111827;
      line-height: 16px;
      font-size: 12px;
      font-weight: 500;
      @media screen and (max-width: 450px) {
        padding-inline: 10px;
        max-width: 175px;
      }

      @media screen and (max-width: 367px) {
        font-size: 10px;
        width: 150px;
      }

      &-demo {
        @media screen and (max-width: 450px) {
          padding-inline: 10px;
          width: 100%;
          max-width: 250px;
        }

        @media screen and (max-width: 367px) {
          font-size: 10px;
          width: 100%;
          max-width: 250px;
        }
      }
    }

    &-headless {
      color: #fff;
      height: 64px;
      padding-inline: 48px;
      line-height: 64px;
      width: 100%;
      background-color: transparent;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e5e7eb;
      background: transparent;
      border-bottom: none;

      @media screen and (max-width: 768px) {
        padding-inline: 20px;
      }
    }
  }

  &__content {
    text-align: center;
    min-height: 120px;
    line-height: 120px;
    color: #fff;
    min-height: calc(100% - 64px);
    overflow: auto;
  }
}
