.badge {
  &-badge {
    margin-right: 10px !important;
    background: #fff !important;
    border: 1px solid var(--light-border) !important;
    color: #000 !important;
    cursor: pointer;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px 10px !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &-badge-disabled {
    margin-right: 10px !important;
    background: #fff !important;
    border: 1px solid var(--light-border) !important;
    color: #000 !important;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px 10px !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    opacity: 0.5;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    gap: 10px;
  }
    &-badge-selected,
    &-badge:hover {
      margin-right: 10px !important;
      background: var(--light-blue-bg) !important;
      border: 1px solid var(--primary) !important;
      color: var(--primary) !important;
      cursor: pointer;
      height: 30px !important;
      line-height: 30px !important;
      padding: 0px 10px !important;
      border-radius: 20px !important;
      font-weight: 500 !important;
      display: flex;
      align-items: center;
      gap: 10px;
  }
  &-badge-selected-disabled {
    margin-right: 10px !important;
    background: var(--light-blue-bg) !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    cursor: not-allowed;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px 10px !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    opacity: 0.5;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
