.share {
  &__share {
    &-logo {
      background-color: var(--light-blue-bg);
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 15px;
    }
    &-title {
      font-size: 20px;
      font-weight: 500;
      color: #111827;
      margin-bottom: 15px;
    }
    &-subtitle {
      color: #111827;
      margin-bottom: 10px;
    }
    &-secondSubtitle {
      font-weight: 500;
    }
    &-CTA {
      width: 100%;
      margin-top: 15px;
    }
    &-greytitle {
      color: #6B7280;
    }
    &-secondary-CTA {
      text-align: center;
      margin-top: 10px;
      text-decoration: underline  ;
    }
  }
}


