.clientform {
  &-form {
    &-label {
      font-weight: 500;
    }
    &-input {
      margin-top: 5px;
      width: 100%;
      & > div {
        gap: 10px 0px;
        flex-wrap: wrap;
      }
    }
    &-badge > sup {
      margin-right: 10px !important;
      background: #fff !important;
      border: 1px solid var(--light-border) !important;
      color: #000 !important;
      cursor: pointer;
      height: 30px !important;
      line-height: 30px !important;
      padding: 0px 10px !important;
      border-radius: 20px !important;
      font-weight: 500 !important;
    }
    &-badge-selected > sup {
      margin-right: 10px !important;
      background: var(--light-blue-bg) !important;
      border: 1px solid var(--primary) !important;
      color: var(--primary) !important;
      cursor: pointer;
      height: 30px !important;
      line-height: 30px !important;
      padding: 0px 10px !important;
      border-radius: 20px !important;
      font-weight: 500 !important;
    }
  }
}
