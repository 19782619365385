.notes {
  &__container {
    height: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &-left {
      max-height: calc(100dvh - 64px);
      overflow: scroll;
      background-color: #fff;
      text-align: left;
      padding: 20px 40px;

      @media screen and (max-width: 1200px) {
        padding: 20px !important;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      &-header {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
      }
      &-user {
        color: var(--grey-text);
      }
      &-text {
        margin: 10px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: var(--grey-text) !important;
      }
      &-text-sub {
        margin: 5px 0px 10px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: var(--grey-text) !important;
      }
      &-text-processing {
        margin: 10px 0px 5px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: #312e81;
      }
      &-error {
        color: rgb(250, 62, 62);
        margin: 10px 0px;
        &-retry {
          margin: 5px;
        }
      }
      &-info {
        color: #6366F1;
        margin: 10px 0px;
        &-retry {
          margin: 5px;
        } 
      }
      &-row {
        border: 1px solid #e5e7eb;
        border-radius: 20px;
        padding: 20px;
        margin: 20px 0px;
        background-color: #fff;
        cursor: pointer;
        @media(hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #f9fafb;
          }
        }
        &-heading {
          font-size: 20px;
          font-weight: 500;
        }
        &-delete {
          display: none;
        }
        @media(hover: hover) and (pointer: fine) {
          &:hover &-delete {
            display: block;
          }
        }
        &-error {
          background-color: #f9fafb;
        }
        &-draft {
          background-color: #f0f0f0;
          border: 1px solid rgba(99, 102, 241, 0.2);
          position: relative;
        }
        &-footer {
          border-top: 1px solid #e5e7eb;
          padding: 10px 0 0px 0;
          & > span {
            font-size: 14px;
            line-height: 20px;
            color: var(--grey-text);
          }
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &-footer-mobile {
          display: none;
          border-top: 1px solid #e5e7eb;
          padding: 10px 0 0px 0;
          & > div {
            font-size: 14px;
            line-height: 20px;
            color: var(--grey-text);
          }
          @media screen and (max-width: 768px) {
            display: flex;
          }
        }
      }
      &-image {
        max-width: 300px;
        text-align: center;
        margin: auto;
      }
      &-arrow {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      &-empty {
        text-align: center;
        text-justify: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: 768px) {
          height: 100%;
          position: relative;
          transform: none;
          top: auto;
          left: auto;
          &-image {
            text-align: center;
            text-justify: auto;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
}
