.members {
  &__container {
    padding: 24px;
    background-color: #fff;
    min-height: calc(100vh - 64px);

    @media screen and (max-width: 768px) {
      padding: 16px;
    }

    &_header {
      margin-bottom: 20px;
      min-height: 64px;
    }

    &_title {
      font-size: 24px !important;
      font-weight: 500 !important;
      margin-bottom: 0 !important;
    }
  }

  &__stats {
    &_card {
      height: 100%;
      padding: 20px;
      background: #F9FAFB;
      border-radius: 8px;
      border: 1px solid #E5E7EB;
      transition: all 0.2s ease;
      display: flex;
      flex-direction: column;

      &:hover {
        background: #F3F4F6;
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      }

      @media screen and (max-width: 768px) {
        padding: 16px;
      }
    }

    &_title {
      font-size: 14px;
      color: #6B7280;
      margin-bottom: 8px;
      font-weight: 500;
    }

    &_value {
      font-size: 28px !important;
      font-weight: 600 !important;
      color: #111827;
      margin-bottom: 8px;
      line-height: 1.2;
    }

    &_detail {
      font-size: 13px;
      color: #6B7280;
      line-height: 1.4;
    }
  }

  &__header {
    margin-bottom: 24px;
  }

  &__table {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;

    :global {
      .ant-table-thead > tr > th {
        background-color: #fafafa;
      }

      .ant-table-selection-column {
        padding-left: 16px;
      }
    }
  }

  &__drawer {
    :global {
      .ant-drawer-header {
        padding: 16px 24px;
      }

      .ant-drawer-body {
        padding: 24px;
        display: flex;
        flex-direction: column;
      }

      .ant-drawer-footer {
        padding: 16px 24px;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}