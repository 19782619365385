@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Inter", sans-serif;
}
.primary-bg {
  background-color: #6366f1;
}
.fullScreen {
  height: 100dvh;
  width: 100dvw;
}
