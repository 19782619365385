.ActiveRecordingView {
  &__container {
    height: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &-text {
      font-size: 1.2rem;
      font-weight: 500;

      @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size: 0.8rem;
      }
    }

    &-left {
      max-height: calc(100dvh - 64px);
      overflow: scroll;
      background-color: #fff;
      text-align: left;
      padding: 20px 40px;

      @media screen and (max-width: 1200px) {
        padding: 20px !important;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      &-header {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
      }
      &-user {
        color: var(--grey-text);
      }
      &-text {
        margin: 10px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: var(--grey-text) !important;
      }
      &-text-sub {
        margin: 5px 0px 10px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: var(--grey-text) !important;
      }
      &-text-processing {
        margin: 10px 0px 5px 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        max-height: 3em; /* (line-height) * (number of lines) */
        color: #312e81;
      }
      &-error {
        color: rgb(250, 62, 62);
        margin: 10px 0px;
        &-retry {
          margin: 5px;
        }
      }
      &-row {
        border: 1px solid #e5e7eb;
        border-radius: 20px;
        padding: 20px;
        box-shadow: 0px 4px 4px 0px #0000000a;
        margin: 20px 0px;
        cursor: pointer;
        @media(hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #f9fafb;
          }
        }
        &-heading {
          font-size: 20px;
          font-weight: 500;
        }
        &-delete {
          display: none;
        }
        @media(hover: hover) and (pointer: fine) {
          &:hover &-delete {
            display: block;
          }
        }
        &-error {
          background-color: #f9fafb;
        }
        &-footer {
          border-top: 1px solid #e5e7eb;
          padding: 10px 0 0px 0;
          & > span {
            font-size: 14px;
            line-height: 20px;
            color: var(--grey-text);
          }
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &-footer-mobile {
          display: none;
          border-top: 1px solid #e5e7eb;
          padding: 10px 0 0px 0;
          & > div {
            font-size: 14px;
            line-height: 20px;
            color: var(--grey-text);
          }
          @media screen and (max-width: 768px) {
            display: flex;
          }
        }
      }
      &-image {
        max-width: 300px;
        text-align: center;
        margin: auto;
      }
      &-arrow {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      &-empty {
        text-align: center;
        text-justify: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: 768px) {
          height: 100%;
          position: relative;
          transform: none;
          top: auto;
          left: auto;
          &-image {
            text-align: center;
            text-justify: auto;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
    &-right {
      max-height: calc(100dvh - 64px);
      overflow-y: scroll;
      background: #eef2ff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-logo {
        height: 72px;

        @media screen and (max-width: 768px) {
          height: auto;
        }
      }
      &-header {
        font-size: 24px !important;
        margin-bottom: 20px !important;
        color: #312e81 !important;

        &-refer {
          color: var(--grey-text);
          cursor: pointer;
          margin-top: 10px;
          font-size: 14px;
          text-decoration: underline;
        }
      }
      &-user {
        text-align: center;
        margin: auto;
      }
      &-recording-card {
        background: #fef2f2;
        border: 1px solid #fecaca;

        & > div {
          padding: 10px !important;
        }
      }
      &-title {
        margin: 10px 0px 20px 0px;
        color: #312e81 !important;
        font-size: 16px !important;
      }
      &-container {
        max-width: calc(100% - 20px);
        margin: 10px;

        @media screen and (max-width: 768px) {
          max-width: 90%;
        }

        & > div {
          padding: 5px 10px !important;
        }
      }
      &-card {
        height: 100%;
        cursor: pointer;
        padding: 0px;
        box-shadow: 0px 1px 2px 0px #0000000f;
        box-shadow: 0px 1px 3px 0px #0000001a;

        @media(hover: hover) and (pointer: fine) {
          &:hover {
            border: 1px solid #3b82f6;
            background-color: #f3f4f6;
          }
        }

        &-logo {
          color: #3b82f6;
          font-size: 20px;
        }
        &-title {
          font-size: 16px;
          font-weight: 500;
        }
        &-description {
          font-size: 12px;
          color: var(--grey-text);
        }
        &-footer {
          margin: 10px;
        }
        &-right-text {
          display: flex;
          align-items: center;
          justify-content: baseline;
        }
      }
    }
  }
  &__modal {
    &-row {
      margin: 5px 0px;
      display: flex;
      flex-direction: column;

      &-bottom {
        margin-top: 20px;
      }

      &-field {
        margin-top: 5px;
      }

      &-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-big {
          padding: 20px;
        }
      }
      &-secondary {
        padding: 10px;
        & span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    &-logo {
      text-align: center;
      margin: 10px 0;
      &-pause {
        width: 472px;
        height: 82px;
        @media screen and (max-width: 768px) {
          max-width: 80% !important;
        }
      }
    }
    &-logo-image {
      max-width: 350px;
      margin: auto;
    }
    &-sub-text {
      color: var(--grey-text);

      @media screen and (min-width: 768px) and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
  &__mobile_view_tabs {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      min-height: calc(100dvh - 64px);
      max-height: calc(100dvh - 64px);
    }
  }
  &__share {
    &-title {
      text-align: center;
      margin: 15px;
    }
  }
}
