.purchase {
  &__main {
    min-height: 100%;
    background-color: #fff;
    &-left {
      border-right: 1px solid #e0e0e0;
      &-item {
        color: #111827;
        align-items: center;
        line-height: 30px;
        padding: 10px;
        cursor: pointer;
      }
    }
    &-right {
      padding: 20px;

      &-card {

        &-title {
          font-weight: bold;
        }
        &-price {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }
        &-sub {
          color: var(--grey-text);
        }
        &-divider {
          border: 1px dashed #e0e0e0;
          margin: 20px 0;
        }
        &-item {
          margin: 5px 0;
        }
      }
    }
  }
}
