// .ant-upload-list-item-container {
//     display: none !important;
// }

.ant-segmented-item {
  padding: 0px 15px;
  color: var(--grey-text);
}

.ant-segmented-item-selected .ant-segmented-item-icon {
  color: #8b5cf6;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  color: #8b5cf6;
}

@media screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-tabs-tab-btn {
  display: flex;
  gap: 10px;
  text-align: center;
}
.ant-tabs-tab-icon {
  margin-inline-end: 0px !important;
}
.ant-tabs-nav {
  background-color: #fff;
}
.ant-tabs-content-holder {
  overflow: scroll;
}
.ant-tabs-tab,
.ant-tabs-nav-list {
  justify-content: center;
}
.ant-radio-wrapper {
  margin: 5px 0px;
}
.ant-drawer-footer {
  text-align: right;
}
.ant-collapse-content {
  border-top: 0px !important;
}
@media screen and (max-width: 768px) {
  .ant-tabs-content.ant-tabs-content-bottom {
    background-color: #eef2ff;
  }
  .ant-drawer .ant-drawer-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
  }

  .ant-card .ant-card-body {
    padding: 15px;
  }

  .ant-card .ant-card-head {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 450px) {
  .ant-segmented-item {
    padding: 0px 10px;
  }
}

@media screen and (max-width: 400px) {
  .ant-segmented-item {
    padding: 0px 5px;
  }
}

@media screen and (max-width: 350px) {
  .ant-segmented-item {
    padding: 0px;
  }
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  background-color: var(--light-blue-bg);
  color: var(--indigo-700);
}
.ant-upload-drag-icon {
  margin: 0px !important;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  font-size: 14px;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 24px;
}
.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal !important;
}
.ant-tour {
  width: 320px;
  max-width: 100%;
}

.ant-modal-body {
  overflow-x: auto;
}