.login {
  &__left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__form {
    justify-content: center;
    align-items: center;
    width: 80%;
    &-container {
      width: 100%;
      margin: auto;
      padding: 0px 40px;

      &-bottom-notification {
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
      }

      @media screen and (max-width: 768px) {
        padding: 0px;
      }

      &-checkbox {
        font-size: 12px;
      }
      &-text {
        font-size: 16px;
        color: var(--grey-text);
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
        a {
          color: #2563eb;
          text-decoration: none;
        }
      }
      &-link {
        a {
          color: #2563eb;
        }
      }
    }

    &-badge {
      background-color: #ecfdf5;
      color: #064e3b;
      padding: 5px 10px;
      border-radius: 20px;

      &-icon {
        color: #10b981;
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
      display: flex;
    }
  }
  &__side {
    padding: 40px 60px;
    background-image: url("../../assets//bg/login_side.svg");
    &-title {
      font-size: 38px !important;
      font-weight: 600 !important;
      margin-bottom: 40px;
      color: #fff;
    }
    &-avatar {
      background: #ffffff33;
      margin-right: 20px;
      width: 54px;
      height: 54px;
    }
    &-feature {
      margin: 20px 0;
      &-title {
        font-size: 18px !important;
        color: #fff;
      }
      &-description {
        color: #fff;
        font-size: 12px !important;
      }
    }
    &-image {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 500px;
    }
    &-image-hippa {
      // position: absolute;
      // bottom: 10px;
      // left: 50%;
      // transform: translateX(-50%);
      height: 50px;
      margin-top: 20px;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.auth-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    z-index: -1;
  }

  &::before {
    top: -150px;
    left: -150px;
    background: radial-gradient(circle, rgba(99,102,241,0.3) 0%, rgba(99,102,241,0) 70%);
  }

  &::after {
    bottom: -150px;
    right: -150px;
    background: radial-gradient(circle, rgba(99,102,241,0.3) 0%, rgba(99,102,241,0) 70%);
  }
}

.marketingSection {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  padding: 2rem;

  @media (max-width: 767px) {
    display: none;
  }
}

.formSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f0f2f5;
  backdrop-filter: blur(10px);
}

.formCard {
  width: 100%;
  max-width: 480px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0 16px;
}

.formTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
  text-align: center;
}

.formSubtitle {
  font-size: 14px !important;
  font-weight: 400;
  text-align: center;
}

.formContainer {
  width: 100%;
  margin-top: 0px;
}

.termsText {
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;

  a {
    color: #2563eb;
    text-decoration: none;
  }
}

.loginLink, .formLinks {
  margin-top: 1rem;
  font-size: 14px;

  a {
    color: #2563eb;
    text-decoration: none;
    margin-left: 0.5rem;
  }
}

.formLinks {
  width: 100%;
}

@media (max-width: 767px) {
  .formSection {
    padding: 1rem;
    background-color: transparent;
  }

  .formCard {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 12px;
  }

  .formTitle {
    margin-bottom: 1.5rem !important;
  }

  .auth-container {
    &::before,
    &::after {
      width: 200px;
      height: 200px;
    }

    &::before {
      top: -100px;
      left: -100px;
    }

    &::after {
      bottom: -100px;
      right: -100px;
    }
  }
}

.marketingCopy {
  max-width: 600px;
  padding: 2rem;

  h2 {
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.testimonial {
  margin-bottom: 4em !important;
  margin-top: 4em !important;
  background-color: rgba(99, 102, 241, 0.10);
}
