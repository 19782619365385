.ai-template-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;

    &-container {
      display: flex;
      align-items: center;
      gap: 12px;
      line-height: 1.5;

      & > h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
    
    &-container-create {
      display: flex;
      align-items: center;
      gap: 12px;
      line-height: 1.5;
      padding-block: 4px;

      & > h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }

    &-close-button {
      line-height: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.45);
      transition: color 0.2s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.88);
      }
    }
  }

  &__empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    padding-inline: 24px;
  }

  &__empty-state-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;

    & > h4 {
      margin-top: 0;
    }

    & > span {
      color: var(--grey-600);
    }
  }

  &__empty-state-icon {
    width: 48px;
    height: 48px;
    stroke: var(--indigo-500);

    &-container {
     padding: 16px;
     border-radius: 50%;
     background-color: var(--indigo-50);
     width: fit-content;
    }
  }

  &__empty-state-button-container {
    margin-top: 16px;
  }

  &__list {
    &-container {
      padding-inline: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-block: 24px;
    }

    &-item {
      margin-bottom: 8px;
      display: inline-block;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 16px;
        border: 1px solid var(--grey-200);
        position: relative;

        & > button {
          background-color: transparent;
          cursor: pointer;
          // line-height: 0;
          padding: 0px;
          border: none;
          text-align: left;
          padding: 16px;
        }
      }

      &-document {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: var(--grey-500);
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 32px;
      }

      &-actions {
        display: flex;
        gap: 16px;

        & > button {
          background-color: transparent;
          cursor: pointer;
          line-height: 0;
          padding: 0px;
          border: none;
        }

        svg {
          width: 18px;
          cursor: pointer;
          stroke: var(--grey-500);
        }

        &-container {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
    }
  }

  &__create-container {
    padding-inline: 24px;
    height: 100%;
    margin-top: 16px;
  }

  &__create-name {
    margin-top: 8px;
  }

  &__create-description {
    margin-top: 20px;

    & > textarea {
      margin-top: 8px;
      height: 100%;
    }
  }

  &__tutorial-container {
    padding-inline: 16px;
    margin-top: 16px;

    & > div {
      padding: 12px;
      background-color: var(--indigo-50);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      
      & > div {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    a {
      text-decoration: none;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }
    }

    h4 {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--indigo-900);
    }

    p {
      margin: 0;
      margin-top: 2px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: var(--grey-500);
    }

    button {
      line-height: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.45);
      transition: color 0.2s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.88);
      }
    }
  }

  &__create-checkbox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }
}

