.createnotemodal {
  &__modal {
    &-row {
      display: flex;
      flex-direction: column;
      &-header {
        background-color: var(--grey-bg);
        width: fit-content;
        padding: 5px 20px;
        margin-left: -24px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        font-weight: 600;
        & > article {
          color: var(--grey-text);
        }
      }

      &-bottom {
        margin-top: 20px;
      }

      &-field {
        margin-top: 5px;
      }

      &-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-big {
          padding: 20px;
        }
      }
      &-secondary {
        padding: 10px;
        & span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    &-logo {
      text-align: center;
      margin: 20px 0;
      &-pause {
        width: 472px;
        height: 82px;
        @media screen and (max-width: 768px) {
          max-width: 80% !important;
        }
      }
    }
    &-logo-image {
      max-width: 350px;
      margin: auto;
    }
    &-notes {
      &-card > div {
        padding: 10px !important;
        border-radius: 8px !important;
        border: 2px solid var(--light-border);
        height: 100% !important;
        display: flex;
      }
      &-card {
        height: 100%;
        flex: 1;
        border: 0px !important;
        cursor: pointer;
        &-selected {
          height: 100%;
          border: 0px !important;
        }
        &-selected > div {
          height: 100%;
          flex: 1;
          display: flex;
          padding: 10px !important;
          border-radius: 8px !important;
          border: 2px solid var(--primary);
        }
        &-avatar {
          background-color: var(--light-blue-bg);
          color: var(--primary);
        }
        &-title {
          font-weight: 500;
        }
        &-desc {
          color: var(--grey-text);
          font-size: 12px;
        }
      }
    }
  }
}
