.tutorial {
    &__container {
        @media screen and (max-width: 768px) {
          min-height: calc(100dvh - 64px - 57px)
        }
        &-tutorial {
          margin-top: 10px;
          border: 1px solid #e5e7eb !important;
          overflow: hidden;
          box-shadow: none !important;
  
          &>div {
            border-bottom: 0px !important;
          }
    
          &>div>div:first-child {
            background-color: #fff;
    
            &>span {
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
    }
    &__state {
      display: flex;
      align-items: start;
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 20px 0px;
    
          &-client {
            min-height: 40dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        &-text {
          color: var(--secondary-text);
          font-size: 20px;
          @media screen and (max-width: 768px) {
            text-align: center;
            max-width: 300px;
          }
        }
    }
    &__step {
      &>div>div>div:nth-of-type(2) {
        background-color: var(--blue-bg) !important;
        border-color: var(--secondary-blue-bg) !important;
        &>span {
          color: var(--primary) !important;
        }
      }
      &-image {
        width: 300px;

        @media screen and (max-width: 768px) {
          display: none !important;
        }
      }
      &-title {
        text-transform: uppercase;
        color: var(--dark-text);
        margin: 0px !important;
        font-weight: 600;
      }

      &-description {
        color: var(--secondary-grey);
        font-size: 16px;
        margin: 0px !important;
      }
    }
    &__show-more {
      padding: 0;
      color: var(--primary);
      font-weight: 500;
      font-size: 16px;
      align-self: flex-start;
      margin-bottom: 10px;
      
      &:hover {
        color: var(--primary-dark);
      }
    }

    &__video-container {
      width: 100%;
      max-width: 600px;
      margin: 10px 0;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      background: #000;
    }

    &__video {
      width: 100%;
      height: auto;
      display: block;
      max-height: 400px;
      object-fit: contain;
    }
}
