.customise-note {
  &__collapse {
    width: 100%;

    &-save {
      width: 100%;
    }
    &-reset {
      text-decoration: underline;
      cursor: pointer;
      color: var(--primary);
    }
    &-checkbox {
      font-size: 12px;
      text-align: left;

      span {
        border-color: #7d7d7d !important;
      }
    }
  }
}
