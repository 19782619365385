.ClientView {
  &__container {
    padding: 10px;
    width: 100%;
    max-height: calc(100vh - 64px - 300px);

    &-active-recording {
      padding: 10px;
      width: 100%;
      max-height: calc(100vh - 64px - 385px);
    }

    &-paused-recording {
      padding: 10px;
      width: 100%;
      max-height: calc(100vh - 64px - 310px);

      @media screen and (max-width: 1200px) {
        max-height: calc(100vh - 64px - 440px);
      }

      @media screen and (max-width: 850px) {
        max-height: calc(100vh - 64px - 430px);
      }
    }

    @media screen and (max-width: 1200px) {
      max-height: calc(100vh - 64px - 400px);

      &-active-recording {
        padding: 10px;
        width: 100%;
        max-height: calc(100vh - 64px - 435px);
      }
    }

    @media screen and (max-width: 1199px) {
      max-height: calc(100vh - 64px - 300px);
    }

    @media screen and (max-width: 991px) {
      max-height: calc(100vh - 64px - 300px);
    }

    @media screen and (max-width: 850px) {
      &-active-recording {
        padding: 10px;
        width: 100%;
        max-height: calc(100vh - 64px - 420px);
      }
    }


    &-no-data {
      color: var(--grey-text);
    }
    &-title {
      font-size: 24px;
      font-weight: 500;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }
    &-client {
      padding: 10px 10px;
      cursor: pointer !important;

      @media(hover: hover) and (pointer: fine) {
        &-selected,
        &:hover {
          background: #eef2ff;
          border-radius: 10px;
          cursor: pointer !important;

          &-icon {
            color: var(--indigo-700) !important;
          }
        }
      }

      &-name {
        color: var(--dark-text);
        //css for one line and ellipsis
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    &-scroll {
      overflow: auto;
    }
    &-scroll-empty {
      overflow: hidden;
      min-height: calc(100vh - 64px - 365px);

      @media screen and (max-width: 1200px) {
        min-height: calc(100vh - 64px - 435px);
      }
      justify-content: center;
    }
  }
}
