:root {
  --secondary-text: #1f2937;
  --grey-text: #6b7280;
  --light-blue-bg: #eef2ff;
  --primary: #6366f1;
  --light-border: #e1e1e1;
  --grey-bg: #f9fafb;
  --dark-text: #111827;
  --blue-bg: #f8f9ff;
  --indigo-900: #312e81;
  --indigo-700: #4338ca;
  --indigo-500: #6366f1;
  --indigo-50: #eef2ff;
  --secondary-grey: #4B5563;
  --secondary-blue-bg:#c7d2ff;
  --grey-500: #6b7280;
  --grey-200: #e5e7eb;
  --grey-600: #4b5563;
  --grey-50: #f9fafb;
}
