.docs {
  &__container {
    background: #fff;
    color: #000;
    padding: 40px;
    &-header {
      font-size: 40px;
      font-weight: bold;
      width: 60%;
      margin: auto;
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        width: 100%;
        font-size: 24px;
        line-height: 44px;
      }
    }
    &-desc {
      text-align: left;
      line-height: 1.5;
      width: 60%;
      margin: auto;
      @media screen and (max-width: 768px) {
        width: 100%;

        ol,
        ul {
          padding-left: 10px;
        }
      }
    }
    &-mid {
      margin-left: -40px;

      @media screen and (max-width: 768px) {
        margin-left: -10px;
      }
    }
  }
}
