.notes {
  &-back {
    margin-bottom: 10px;
  }
  &__main {
    width: 100%;
    margin: 10px auto;
    border-radius: 10px;
    padding: 0px;
    color: #111827;

    &-container {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      margin: 0px 0px 10px 0px;
    }

    &-back {
      display: flex;
      color: var(--grey-text);
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      padding: 0px;
    }

    &-detail {
      margin: 20px 0px 0px 0px;
      color: var(--grey-text);

      @media screen and (max-width: 768px) {
        display: none;
      }

      &-white {
        color: #fff;
      }
    }

    &-detail-mobile {
      display: none;
      color: var(--grey-text);
      margin: 20px 0px;

      @media screen and (max-width: 768px) {
        display: flex;
        margin: 20px 0px 0px 0px;
      }
    }

    & span {
      line-height: 25px;
    }

    &-section {
      width: 100%;
      box-shadow: 0px 4px 4px 0px #0000000a;

      &-span {
        @media screen and (max-width: 768px) {
          font-size: 12px !important;
        }
      }

      &-segment {
        margin-bottom: 20px;
      }

      &-textarea {
        border: 0px;
        box-shadow: none;
        font-size: 14px;
        padding: 10px 0px;
      }

      &-action {
        text-align: right;
      }

      &-title {
        font-size: 20px;
        font-weight: 500;
      }
      &-copy-button {
        color: var(--grey-text);
        font-size: 12px;
      }
      &-save-button-container {
        margin-top: 50px;
        text-align: right;

        @media screen and (max-width: 500px) {
          margin-top: 80px;
        }
      }
      &-save-button {
        background: #eef2ff;
        color: #6366f1;
        font-weight: 500;

        @media(hover: hover) and (pointer: fine) {
          &:hover {
            background: #6366f1;
            color: #fff;
          }
        }
      }
      &-title-container {
        flex-wrap: wrap;
        max-width: 100%;
        word-break: break-all;
      }
      &-header {
        font-size: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 400px;
          display: block;
        }

        @media screen and (max-width: 1200px) {
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;
            display: block;
          }
        }

        @media screen and (max-width: 991px) {
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 150px;
            display: block;
          }
        }

        @media screen and (max-width: 768px) {
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;
            display: block;
          }
        }

        &-action {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
        }

        &-save {
          cursor: pointer;
          color: var(--grey-text);
          margin: 0px 10px;
        }
        &-save {
          color: #fff;
        }
        &-action {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &-action-mobile {
          display: none;
          @media screen and (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }
        }
      }
      &-description {
        font-size: 16px;
        text-align: left;
        margin: 10px 0px;

        &-col {
          font-size: 14px;
          &-label {
            min-width: 80px;
          }
        }
      }
    }
  }
  &__nudge-insight {
    & > div:first-child {
      background-color: #6366f1;
      color: #fff;
      border-radius: 10px 10px 0px 0px;
    }

    @media screen and (max-width: 767px) {
      :global {
        .ant-card-head-title,
        .ant-card-extra {
          text-wrap: wrap;
          font-size: 12px;
        }
      }
    }
  }
  &-full-width {
    width: 100%;
  }

  &__container-right-container-bottomcta-text {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
