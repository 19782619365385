.loading-modal {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.2); /* Black background with opacity */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000000;
}

.loading-circle {
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
