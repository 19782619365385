.RecordCTA {
  &__container {
    height: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
    &-right {
      max-height: calc(100dvh - 64px);
      overflow: scroll;
      background: #eef2ff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-header {
        font-size: 24px !important;
        margin-bottom: 0px !important;
        font-weight: 600;
        color: var(--secondary-text) !important;
        display: none;

        &-container {
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }

        @media screen and (max-width: 768px) {
          display: block;
        }

        &-refer {
          color: var(--grey-text);
          cursor: pointer;
          margin-top: 10px;
          font-size: 14px;
          text-decoration: underline;
        }
      }
      &-title {
        margin: 10px 0px 20px 0px;
        color: #312e81 !important;
        font-size: 16px !important;
      }
      &-container {
        padding: 15px 5px;
        margin: 10px !important;
        background-color: #eef2ff;
        border-radius: 10px;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }

        &-bottomcta {
          line-height: 20px;
          color: #000;
          &-text {
            font-size: 12px;
            color: blue;
            cursor: pointer;
            @media screen and (max-width: 1000px) {
              font-size: 10px;
            }
            @media screen and (max-width: 768px) {
              font-size: 14px;
              margin-top: 20px;
            }
          }
        }
      }
      &-card {
        height: 100%;
        cursor: pointer;
        padding: 0px;
        box-shadow: 0px 1px 2px 0px #0000000f;
        box-shadow: 0px 1px 3px 0px #0000001a;
        border-radius: 16px;

        &-button {
          display: block;
          width: 100%;

          @media screen and (max-width: 991px) {
            &>span {
              font-size: 12px;
            }
          }
        }

        & > div {
          padding: 12px !important;
        }

        @media(hover: hover) and (pointer: fine) {
          &:hover {
            border: 1px solid #3b82f6;
            background-color: #f3f4f6;
          }
        }

        &-logo {
          color: var(--indigo-500);
          font-size: 20px;
          flex-shrink: 0;
          width: 40px;
          height: 40px;
        }
        &-seconrdary-logo {
          color: #3b82f6;
          font-size: 20px;
          display: none;
          margin-bottom: 7px;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }
        &-title {
          font-size: 16px;
          font-weight: 500;

          color: var(--secondary-text);

          &-secondary {
            font-size: 12px;
            font-weight: 400;

            @media screen and (max-width: 768px) {
              font-size: 16px;
              font-weight: 500;
              color: var(--secondary-text);
            }
          }
        }
        &-description {
          font-size: 12px;
          color: var(--grey-text);
          margin-top: 2px;
        }
        &-secondary-description {
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
            font-size: 12px;
            color: var(--grey-text);
          }
        }
        &-footer {
          margin: 10px;
        }
        &-right-text {
          display: flex;
          align-items: center;
          justify-content: baseline;
        }
      }
    }
  }
}
